import React from "react"

// Components
import { Link, graphql } from "gatsby"



const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  console.log(pageContext);
  console.log(data);
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  return (
    <div>
      <h1>{tagHeader}</h1>
      <ul>
        {edges.map(({ node }) => {
          const { title } = node.frontmatter
          return (
            <li key={node.slug}>
              <Link to={`/posts/${node.slug}`}>{title}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}


export default Tags

export const query = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___datePublished], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
					slug
          frontmatter {
            title
          }
        }
      }
    }
  }
` 